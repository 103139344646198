<template>
  <div>
    <b-tabs class="mt-2">
      <crowd-fundraising-form
        :submit-functions="{
          submitCrowdFund:submitMainInfo,
          addCrowdFundLocation,
          addCrowdFundPlan,
          addCrowdFundMedia,
        }"
        :crowdfund.sync="crowdfund"
      />
    </b-tabs>
  </div>
</template>
<script>
import CrowdFundraisingForm from '@/common/components/Fundraising/CrowdFundraising/CrowdFundraisingForm.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'

export default {
  name: 'AddCrowdFund',
  components: { CrowdFundraisingForm },
  setup() {
    const {
      addCrowdFundMainInfo, editCrowdFundMainInfo, addCrowdFundLocation, addCrowdFundMedia, addCrowdFundPlan, crowdfund, mainInfoSubmitted,
    } = FundraisingApi()

    return {
      addCrowdFundMainInfo, editCrowdFundMainInfo, addCrowdFundLocation, addCrowdFundMedia, addCrowdFundPlan, crowdfund, mainInfoSubmitted,
    }
  },
  computed: {
    submitMainInfo() {
      return this.mainInfoSubmitted ? this.editCrowdFundMainInfo : this.addCrowdFundMainInfo
    },
  },
}
</script>
<style lang="scss">

</style>
